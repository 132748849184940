import React from 'react'
import Articletable from './Article_table'
function Allitems() {
  return (
    <div>
      <Articletable/>
    </div>
  )
}

export default Allitems
