import logo from './logo.svg';
import './App.css';
import { Fragment } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './Component/Login';
import Dashboard from './Component/Dashboard';
function App() {
  return (
    <Fragment>
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={<Login/>}/>
          <Route path='/dashboard/*' element={<Dashboard/>}/>
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
