import axios from 'axios'
import React, { useEffect, useMemo, useState } from 'react'
import { useTable } from 'react-table'
import { COLUMNS } from './Columns'
function Articletable() {
    const [article, Setarticle] = useState([])

    const API='https://mywebsite.equickkart.com/api/articledata'
    useEffect(() => {
        const fetchdata = (url) => {
            return axios.get(url)
                .then(res => {
                    Setarticle(res.data);
                }).catch(err => {
                    console.log(err);
                })
        }
        fetchdata(API);
    }, []);

    const columns = useMemo(() => COLUMNS, [])
    const data = useMemo(() => article, [article])
    var tableinstance = useTable({
        columns,
        data
    })
    
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableinstance
    

    if (data.length!==0) {
        return (
            <>
                <table {...getTableProps()}>
                    <thead>
                        {
                            headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {
                           rows.map((row) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {
                                            row.cells.map((cell) => {
                                                return (
                                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>

                                                )
                                            })
                                        }
                                    </tr>

                                )
                            })
                        }
                    </tbody>
                </table>
            </>
        )
    } else {
        return <h1>No records</h1>;
    }
}

export default Articletable
