import React from 'react'

function Navbar() {
    function Toggle() {
        var element = document.getElementById("menu-id");
        
        element.classList.toggle("mystyle");
     }
  return (
    <div>
      <div className='header'>
          <h6 className='logo'>Lable Printing</h6>
          <div style={{cursor:'pointer'}} onClick={Toggle}>
            <div className='menu-icon'></div>
            <div className='menu-icon'></div>
            <div className='menu-icon'></div>
          </div>
          
        </div>
    </div>
  )
}

export default Navbar
