import axios from 'axios'
import React, { useEffect } from 'react'
import { useState } from 'react'

function Newitem() {
    const [new_item, setnew_item] = useState([])
    // console.log(new_item[0]);
    const get_new_item = () => {
        axios.get('https://mywebsite.equickkart.com/api/get-new_item')
            .then(result => setnew_item(result.data))
            .catch(err => console.log(err))
    }
    useEffect(() => {
        get_new_item()
    }, [])
    return (
        <div>
            {
                new_item && new_item.map((data, key) => {
                    console.log(data);
                    return (
                        <table key={key}>
                            <thead>
                                <tr>
                                    <th>Article</th>
                                    <th>Article_Description</th>
                                    <th>Arabic_Description_Long</th>
                                    <th>English_POS_Description</th>
                                    <th>Arabic_POS_Description</th>
                                    <th>	Del.</th>
                                    <th>BUoM</th>
                                    <th>VUoM</th>
                                    <th>Net_Weight</th>
                                    <th>	UoM</th>
                                    <th>	EA/CV</th>
                                    <th>KG/CV</th>
                                    <th>EA Barcode</th>
                                    <th>	Aditional EANs</th>
                                    <th>Total shelf life</th>
                                    <th>	Brand Name</th>
                                    <th>	Brand</th>
                                    <th>Scale Group</th>
                                    <th>	Scale Group Text</th>
                                    <th>	Tax Class</th>
                                    <th>	Valuation Class</th>
                                    <th>	expiry_date</th>
                                    <th>	Quantity</th>



                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{data.Article}</td>
                                    <td>{data.Article_Description}</td>
                                    <td>{data.Arabic_Description_Long}</td>
                                    <td>{data.English_POS_Description}</td>
                                    <td>{data.Arabic_POS_Description}</td>
                                    <td>{data.Del}</td>
                                    <td>{data.BUoM}</td>
                                    <td>{data.VUoM}</td>
                                    <td>{data.Net_Weight}</td>
                                    <td>{data.UoM}</td>
                                    <td>{data.EACV}</td>
                                    <td>{data.KGCV}</td>
                                    <td>{data.EABarcode}</td>
                                    <td>{data.AditionalEANs}</td>
                                    <td>{data.Totalshelflife}</td>
                                    <td>{data.BrandName}</td>
                                    <td>{data.Brand}</td>
                                    <td>{data.ScaleGroup}</td>
                                    <td>{data.ScaleGroupText}</td>
                                    <td>{data.TaxClass}</td>
                                    <td>{data.ValuationClass}</td>
                                    <td>{data.expiry_date}</td>
                                    <td>{data.Quantity}</td>

                                </tr>
                            </tbody>
                        </table>
                    )
                })
            }
        </div>
    )
}

export default Newitem
