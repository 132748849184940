import React, { useState } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import {BiSolidDashboard} from 'react-icons/bi';
import {FaFileUpload} from 'react-icons/fa'
function Menubar() {

    const handleFileUpload = (event) => {
        // get the selected file from the input
        const file = event.target.files[0];
        // create a new FormData object and append the file to it
        const formData = new FormData();
        formData.append("file", file);
        // make a POST request to the File Upload API with the FormData object
        axios
            .post("https://mywebsite.equickkart.com/api/import-excel", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                // handle the response
                console.log(response);
                toast("File uploaded")
                window.location.reload()
            })
            .catch((error) => {
                // handle errors
                toast(error.response.data)

            });
    };

    return (
        <div>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <section>
            <div className='manu' id='menu-id'>
                <h1 className='logo1'>Admin</h1>
                <ul className='nav'>
                    <li><BiSolidDashboard className='margin'/><a href='/dashboard'>Dashboard</a></li>
                    <li><FaFileUpload className='margin'/><a href='#'><input type="file" id="actual-btn" onChange={handleFileUpload} hidden/><label htmlFor='actual-btn'>Upload</label></a></li>
                    
                </ul>
                
            </div>
            </section>
        </div>
    )
}

export default Menubar
