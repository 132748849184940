import React from 'react'
import Navbar from './Navbar'
import Menubar from './Menubar'
import Articletable from './Article_table'
import { Link, Route, Routes } from 'react-router-dom'
import Allitems from './Allitems'
import Newitem from './New-item'

function Dashboard() {
    
    return (
        <>
            <div>
                <Navbar/>
                <Menubar/>
                
                <div>
                    <li><Link to='/dashboard/item'>All items</Link></li>
                    <li><Link to='/dashboard/new-item'>New item</Link></li>
                </div>
                <Routes>
                    <Route path='/item' element={<Allitems/>}/>
                    <Route path='/new-item' element={<Newitem/>}/>
                </Routes>
            </div>
        </>
    )
}

export default Dashboard
