import React, { Fragment, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Login() {
    const [uname, setuname] = useState()
    const [password, setpassword] = useState()
    const [file, setfile] = useState()
    const navigate = useNavigate();

    function handleFile(e) {
        // Getting the files from the input
        let files = e.target.files;

        setfile(files);
    }
    const handlesubmit = () => {
        if (uname == "root" && password == "dilip@123") {
                    navigate('/dashboard');
        } else {
            toast("Invalid credential...")
        }

    }
    return (
        <Fragment>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className='login'>
                <Form encType="multipart/form-data" onSubmit={(e) => handlesubmit(e.preventDefault())}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Username:</Form.Label>
                        <Form.Control type='text' onChange={(e) => setuname(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Password:</Form.Label>
                        <Form.Control type='password' onChange={(e) => setpassword(e.target.value)} />
                    </Form.Group>
                    {/* <Form.Group>
                        <Form.Label>Upload file: </Form.Label>
                        <Form.Control name="import-excel"
                            type='file' accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            onChange={(e) => handleFile(e)} />
                    </Form.Group> */}
                    <br />
                    <Form.Control type="submit" />

                </Form>

            </div>
        </Fragment>
    )
}
export default Login
